import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, PatternValidator } from '@angular/forms';
import { Router } from '@angular/router';
import { RegisterService } from 'src/app/services/register/register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  response: any;
  error = false;

  forms: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private registerService: RegisterService) { }

  form = this.fb.group({
    name: ['', Validators.required],
    mail: ['', [Validators.required, Validators.email]],
    mobile: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
    gender: ['', Validators.required],
    password: ['', Validators.required]
  });

  get f() { return this.form.controls; }

  ngOnInit() {
  }

  onSubmit(form: HTMLFormElement) {
    this.registerService.userRegistration(form.value).subscribe((response: any) => {
      this.router.navigate(['login']);
    });
  }

}
