import { Injectable } from '@angular/core';
import { RootService } from '../root.service';
import { HttpClient } from '@angular/common/http';
import { UserModule } from '../../modules/user/user.module';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, private rootService: RootService) { }

  baseUrl = this.rootService.roklantisUrl;
  reqHeader = this.rootService.roklantisHeader;

  userLogin(user: any): Observable<any> {
    const body: UserModule = {
      mail: user.mail,
      password: user.password
    };
    return this.http.post(`${this.baseUrl}/api/v2/login`, body, {headers: this.reqHeader, observe: 'response'});
  }

}
