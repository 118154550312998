import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../services/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  data: any;
  totalEbooks: number;
  totalSales: number;
  totalDownloads: number;

  constructor(private dashboardService: DashboardService, private router: Router,) { }

  ngOnInit() {

    this.dashboardService.getAllData().subscribe((response: any) => {
      if (response.flag === 'BAD_TOKEN' || response.flag === 'VALIDATION_FAILED') {
        localStorage.removeItem('rokr');
        this.router.navigate(['login']);
      } else {
        this.data = response;
        this.totalEbooks = this.data.total_ebooks;
        this.totalSales = this.data.total_sales.toFixed(0);
        this.totalDownloads = this.data.total_downloads;
      }
    });

  }

}
