import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BooksService } from '../services/books/books.service';

@Component({
  selector: 'app-book-lists',
  templateUrl: './book-lists.component.html',
  styleUrls: ['./book-lists.component.css']
})
export class BookListsComponent implements OnInit {

  bookLists: Array<number | string>;
  books: Array<number | string>;
  count: number;

  currentPage = 1;
  size = 40;
  isActive = 1;

  constructor(private bookService: BooksService, private router: Router) { }

  ngOnInit() {

    this.getBook();

  }

  getBook() {
    this.bookService.getBooksInfo(this.currentPage, this.size, this.isActive).subscribe((response: any): void => {
      this.bookLists = response.book_list;
      if (response.flag === 'BAD_TOKEN' || response.flag === 'VALIDATION_FAILED') {
        this.router.navigate(['/login']);
      } else {
        this.bookLists.forEach((element: any): void => {
          this.books = element.books;
          this.count = this.books.length;
        });
      }
    });
  }

  prev() {
    this.currentPage = --this.currentPage;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }
    this.getBook();
  }

  next() {
    this.currentPage = ++this.currentPage;
    this.getBook();
  }

}
