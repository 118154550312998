import { Injectable } from '@angular/core';
import { RootService } from '../root.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient, private rootService: RootService) { }

  baseUrl = this.rootService.nautilusUrl;
  reqHeader = this.rootService.nautilusHeader;

  getAllData() {
    return this.http.get(`${this.baseUrl}/api/v2/publisher_panel/dashboard`, {headers: this.reqHeader});
  }

}
