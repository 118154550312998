import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RootService } from '../root.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResetService {

  constructor(private http: HttpClient, private rootService: RootService) { }

  baseUrl = this.rootService.roklantisUrl;
  reqHeader = this.rootService.roklantisHeader;

  sendOtp(form: HTMLFormElement): Observable<any> {
    const body = {
      mail: form.mail,
      mobile: form.mobile,
    };
    return this.http.post(`${this.baseUrl}/api/v2/password/notify`, body, {headers: this.reqHeader});
  }

  resetPassword(mail: HTMLFormElement, mobile: HTMLFormElement, password: HTMLFormElement, otpToken: HTMLFormElement): Observable<any> {
    const body = {
      mail,
      mobile,
      password,
      otp_token: otpToken
    };
    return this.http.post(`${this.baseUrl}/api/v2/password/reset`, body, {headers: this.reqHeader});
  }

}
