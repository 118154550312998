import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../services/dashboard/dashboard.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  data: any;
  publisherName: string;
  publisherImage: string;

  constructor(private router: Router, private dashboardService: DashboardService) { }

  ngOnInit() {

    this.dashboardService.getAllData().subscribe((response: any) => {
      if (response.flag === 'BAD_TOKEN' || response.flag === 'VALIDATION_FAILED') {
        this.router.navigate(['/login']);
      } else {
        this.data = response;
        this.publisherName = this.data.publisher_name_en;
        this.publisherImage = this.data.image_path;
      }
    });

  }

  logout() {
    localStorage.removeItem('rokr');
    this.router.navigate (['login']);
  }


}
