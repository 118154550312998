import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login/login.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  response: any;
  error = false;

  forms: FormGroup;

  constructor(private loginService: LoginService, private fb: FormBuilder, private router: Router) { }

  form = this.fb.group({
    mail: ['', Validators.required],
    password: ['', Validators.required]
  });

  get f() { return this.form.controls; }


  ngOnInit() {

    if (localStorage.getItem('rokr') !== null) {
      this.router.navigate (['../dashboard']);
    } else {
      this.router.navigate (['login']);
    }

  }

  onSubmit(form: HTMLFormElement) {

    this.loginService.userLogin(form.value).subscribe(res => {
      this.response = res;
      if (this.response.body.flag === 'SUCCESS') {
        localStorage.setItem('rokr', res.headers.get('rokr'));
        this.router.navigate (['../dashboard']);
        location.reload();
      } else {
        this.error = true;
        this.router.navigate (['login']);
      }
    });

  }

}
