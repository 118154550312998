import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { PaginationModule } from 'ngx-bootstrap/pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './account/register/register.component';
import { LoginComponent } from './account/login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BookListsComponent } from './book-lists/book-lists.component';
import { AuthGuard } from './auth.guard';
import { RootService } from './services/root.service';
import { LoginService } from './services/login/login.service';
import { DashboardService } from './services/dashboard/dashboard.service';
import { BooksService } from './services/books/books.service';
import { UploadComponent } from './upload/upload.component';
import { UploadService } from './services/upload/upload.service';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { ResetService } from './services/reset-password/reset.service';
import { SetPasswordComponent } from './account/set-password/set-password.component';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    LayoutComponent,
    DashboardComponent,
    BookListsComponent,
    UploadComponent,
    ResetPasswordComponent,
    SetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PaginationModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [AuthGuard, RootService, LoginService, ResetService, DashboardService, BooksService, UploadService],
  bootstrap: [AppComponent]
})
export class AppModule { }
