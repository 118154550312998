import { Injectable } from '@angular/core';
import { RootService } from '../root.service';
import { RegisterModule } from '../../modules/register/register.module';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient, private rootService: RootService) { }

  baseUrl = this.rootService.roklantisUrl;
  reqHeader = this.rootService.roklantisHeader;

  userRegistration(user: any) {
    const body: RegisterModule = {
      name: user.name,
      mail: user.mail,
      mobile: user.mobile,
      gender: user.gender,
      password: user.password
    };
    return this.http.post(`${this.baseUrl}/api/v2/register`, body, {headers: this.reqHeader});
  }

}
