import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { InvalidFileType } from '../modules/InvalidFileType';
import { UploadService } from '../services/upload/upload.service';

declare var $: any;

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  allItems: Array<number | string>;
  items: Array<number | string>;
  bookId: number;
  mainFile: File;
  uploadFile: File;
  count: number;
  status = String(0);
  bookName: string;

  isSuccess = false;
  expand = false;
  showMessage = false;
  uploadMsg = false;

  currentPage = 1;
  size = 40;
  isActive = 1;

  constructor(private fb: FormBuilder, private router: Router, private uploadService: UploadService) { }

  form = this.fb.group({
    productId: ['', Validators.required],
    mainFile: ['', [Validators.required, InvalidFileType]]
  });

  get f() { return this.form.controls; }

  forms = this.fb.group({
    file: ['', [Validators.required, InvalidFileType]]
  });

  get f2() { return this.forms.controls; }

  ngOnInit() {

    this.expand = false;

    this.getDataFilter();

  }

  expandForm() {
    this.expand = !this.expand;
  }

  getId(id: number, bookName: string) {
    this.bookId = id;
    this.bookName = bookName;
  }

  commonFileInput(event: Event) {
    this.mainFile = (event.target as HTMLInputElement).files[0];
  }

  specificFileInput(event: Event) {
    this.uploadFile = (event.target as HTMLInputElement).files[0];
  }

  closeAlert() {
    this.isSuccess = false;
  }


  filter(status: string) {
    this.currentPage = 1;
    this.uploadService.filter(this.currentPage, this.size, status, this.isActive)
    .subscribe((res: {flag: number | string, _count: number, items: Array<any>}) => {
      if (res.flag === 'BAD_TOKEN' || res.flag === 'VALIDATION_FAILED') {
        this.router.navigate(['/login']);
      } else {
        this.items = res.items;
        this.count = res._count;
      }
    });
  }

  getDataFilter() {
    this.uploadService.filter(this.currentPage, this.size, this.status, this.isActive)
    .subscribe((res: {flag: number | string, _count: number, items: Array<any>}) => {
      if (res.flag === 'BAD_TOKEN' || res.flag === 'VALIDATION_FAILED') {
        this.router.navigate(['/login']);
      } else {
        this.items = res.items;
        this.count = res._count;
      }
    });
  }

  prev() {
    this.currentPage = --this.currentPage;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }
    this.getDataFilter();
  }

  next() {
    this.currentPage = ++this.currentPage;
    this.getDataFilter();
  }

  onSubmit(form: HTMLFormElement) {
    const mainFileType = this.mainFile.name.split('.')[1];
    this.uploadService.mainUploadFile(this.mainFile, form.value.productId, mainFileType)
    .subscribe((res: {flag: number | string, _count: number, items: Array<any>}) => {
      if (res.flag === 'BAD_TOKEN' || res.flag === 'VALIDATION_FAILED') {
        this.router.navigate(['/login']);
      } else {
        this.isSuccess = true;
      }
      this.form.reset();
      this.getDataFilter();
    });
  }

  onUpload() {
    this.uploadMsg = true;
    const fileType = this.uploadFile.name.split('.')[1];
    this.uploadService.uploadFile(this.uploadFile, this.bookId, fileType)
    .subscribe((res: any) => {
      if (res.flag === 'BAD_TOKEN' || res.flag === 'VALIDATION_FAILED') {
        this.router.navigate(['/login']);
      }
      this.uploadMsg = false;
      $('#exampleModalCenter').modal('hide');
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 3000);
      this.getDataFilter();
    });
    this.forms.reset();
  }

  closeModal() {
    location.reload();
  }

}
