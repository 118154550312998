import { Injectable } from '@angular/core';
import { RootService } from '../root.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private http: HttpClient, private rootService: RootService) { }

  baseUrl = this.rootService.packmanUrl;
  reqHeader = this.rootService.packmanHeader;
  apiKey = this.rootService.packmanKey;

  filter(page: number, size: number, status: string, isActive: number): Observable<any> {
    const body = {
      page,
      size,
      upload_state: parseFloat(status),
      isActive
    };
    return this.http.post(`${this.baseUrl}/packman/api/v1/files`, body, {headers: this.reqHeader});
  }

  mainUploadFile(file: File, id: number, fileType: string): Observable<any> {
    const formData = new FormData();
    formData.append('raw_file', file);
    const bookId = id.toString();
    const reqHeaderUpload = new HttpHeaders({
      source_name: 'rokomari',
      source_id: bookId,
      file_extension: fileType,
      api_key: this.apiKey,
      rokr: localStorage.getItem('rokr')
    });
    return this.http.post(`${this.baseUrl}/packman/api/v1/file/upload`, formData, {headers: reqHeaderUpload});
  }

  uploadFile(file: File, id: number, fileType: string): Observable<any> {
    const formData = new FormData();
    formData.append('raw_file', file);
    const bookId = id.toString();
    const reqHeaderUpload = new HttpHeaders({
      source_name: 'ebook',
      source_id: bookId,
      file_extension: fileType,
      api_key: this.apiKey,
      rokr: localStorage.getItem('rokr')
    });
    return this.http.post(`${this.baseUrl}/packman/api/v1/file/upload`, formData, {headers: reqHeaderUpload});
  }

}
