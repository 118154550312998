import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ResetService } from '../../services/reset-password/reset.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  forms: FormGroup;

  otp = false;
  error = false;

  constructor(private resetService: ResetService, private fb: FormBuilder, private router: Router) { }

  form = this.fb.group({
    mail: ['', [Validators.required, Validators.email]],
    mobile: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]]
  });

  get f() { return this.form.controls; }

  ngOnInit() {
  }

  onSubmit(form: HTMLFormElement) {
    this.resetService.sendOtp(form.value).subscribe((res: {flag: string}) => {
      if (res.flag !== 'ERROR') {
        localStorage.setItem('mail', form.value.mail);
        localStorage.setItem('mobile', form.value.mobile);
        this.router.navigate(['/set-password']);
      } else {
        this.error = true;
        this.router.navigate(['/reset']);
      }
    });
  }

}
