import { Component, OnInit } from '@angular/core';
import { ResetService } from 'src/app/services/reset-password/reset.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {

  email = localStorage.getItem('mail');
  mob = localStorage.getItem('mobile');

  error = false;

  constructor(private resetService: ResetService, private fb: FormBuilder, private router: Router) { }

  form = this.fb.group({
    mail: '',
    mobile: '',
    password: ['', Validators.required],
    otp_token: ['', Validators.required]
  });

  get f() { return this.form.controls; }

  ngOnInit() {
  }

  onSubmit(mail: HTMLFormElement, mobile: HTMLFormElement, password: HTMLFormElement, otpToken: HTMLFormElement) {
    this.resetService.resetPassword(mail.value, mobile.value, password.value, otpToken.value).subscribe((res: any) => {
      if (res.flag === 'RESET') {
        this.router.navigate(['/login']);
      } else {
        this.error = true;
      }
    });
  }

}
