import { AbstractControl } from '@angular/forms';

export const InvalidFileType = (control: AbstractControl) => {
    const file = control.value;
    const fileType = file.split('.').pop();
    if (fileType !== 'doc' && fileType !== 'docx' && fileType !== 'qxd' && fileType !== 'qxp') {
        return {
            isError: true
        };
    }
    return null;
};
