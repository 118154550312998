import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './account/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BookListsComponent } from './book-lists/book-lists.component';
import { LayoutComponent } from './layout/layout.component';
import { RegisterComponent } from './account/register/register.component';
import { UploadComponent } from './upload/upload.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { SetPasswordComponent } from './account/set-password/set-password.component';

const routes: Route[] = [

  {
    path: 'dashboard',
    component: LayoutComponent,
    children: [
      { path: '', component: DashboardComponent, pathMatch: 'full' },
      { path: '../dashboard', component: DashboardComponent },
      { path: 'books', component: BookListsComponent },
      { path: 'upload', component: UploadComponent }
    ],
    canActivate: [AuthGuard]
  },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'reset', component: ResetPasswordComponent },
  { path: 'set-password', component: SetPasswordComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
