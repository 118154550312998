import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RootService {

  // Live Server
    readonly roklantisUrl: string = 'https://roklantis.muthoboi.com';
    readonly nautilusUrl: string = 'https://nautilus.muthoboi.com';
    readonly packmanUrl: string = 'https://packman.muthoboi.com';

  // Stage Server
//  readonly roklantisUrl: string = 'http://stage.roklantis.muthoboi.com';
//  readonly nautilusUrl: string = 'http://stage.nautilus.muthoboi.com';
//  readonly packmanUrl: string = 'http://stage.packman.muthoboi.com';

  // Local Server
  // readonly roklantisUrl: string = 'http://192.168.11.169:5001';
  // readonly nautilusUrl: string = 'http://192.168.11.169:5002';
  // readonly packmanUrl: string = 'http://192.168.11.169:6045';

  roklantisKey = 'b!@0al$%ash3^&*4tebDEWa3ed1#$0#$3#$4';
  nautilusKey = '3ouWDd23daXCm3oy!@(ou$nak#$@#SDDCnfjd89wdjeksdkn*&';
  packmanKey = 'thereisnnospecificapikeyintheend!@@#$23bh2#';

  roklantisHeader = new HttpHeaders({'Content-Type': 'application/json', api_key: this.roklantisKey});

  nautilusHeader = new HttpHeaders({'Content-Type': 'application/json', api_key: this.nautilusKey, rokr: localStorage.getItem('rokr')});

  packmanHeader = new HttpHeaders({'Content-Type': 'application/json', api_key: this.packmanKey, rokr: localStorage.getItem('rokr')});

  constructor() { }
}
